













































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import { UserApi } from "@/networks/UserApi";
import UserAutoComplete from "@/views/components/inputs/user.vue";
import Office from "@/views/components/inputs/office.vue";
import { EventApi } from "@/networks/EventApi";
import Enum from "@/config/enum";
import MobileList from "@/views/pages/events/view-components/mobile.vue";
import GeneralApi from "@/networks/GeneralApi";
import DatetimePicker from "vue-persian-datetime-picker";
import fileUploader from "@/views/components/inputs/fileUploader.vue";
import eventsDialog from "@/views/components/pages/dashboard/eventsDialog.vue";
import Comments from "@/views/components/pages/Comments/Comments.vue";

@Component({
  components: {
    UserAuto: UserAutoComplete,
    office: Office,
    fileUploader,
    "datetime-picker": DatetimePicker,
    MobileList: MobileList,
    eventsDialog,
    Comments
  }
})
export default class ListEvent extends Vue {
  private data: any = [];
  private items: any = [];
  private staff = [];
  private roles = localStorage.getItem("roles") || [];
  private types = Enum.eventTypes;
  private form: any = {};
  private page = 1;
  dialog = false;
  dialog_comments = false;
  private formReminder = {};
  private bms = process.env.VUE_APP_BMS;
  private dialog1 = false;
  private datePopup = false;
  private payDialog = false;
  private copyDialog = false;
  private paymentTypes = Enum.TypePayment;
  private payform: Record<string, any> = {
    status: "1"
  };
  private errors: Record<string, Array<string>> = {};
  private eventStatus = Enum.eventStatusAdmin;

  mounted(): void {
    if (window.innerWidth < 600) {
      setTimeout(() => {
        const pageQuery: any = this.$route.query.page;
        if (pageQuery == undefined || pageQuery > 1) {
          this.page = 1;
        }
        document.addEventListener("scroll", this.HandleScrollMobile);
      }, 1000);
    }
    this.getStaff();
    this.page = Number(this.$route.query.page || 1);
    if (this.$route.query.search) {
      this.form.q = this.$route.query.search;
    }
    this.events();
  }

  private timeout: any = null;

  HandleScrollMobile(e: any): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (
        e.target.scrollingElement.clientHeight +
          e.target.scrollingElement.scrollTop >=
        e.target.scrollingElement.scrollHeight - 100
      ) {
        this.page++;
      }
    }, 300);
  }

  copyLinkToClipboard(id: string): void {
    let copyText = (document as any).getElementById("pay-link-copy-" + id);
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    navigator.clipboard
      .writeText(copyText.value)
      .then(() => {
        store.commit("showError", {
          message: "لینک با موفقیت کپی شد ",
          color: "success"
        });
      })
      .catch((error) => {
        alert(`Copy failed! ${error}`);
      });
  }

  public async setReminder(eventId: number): Promise<void> {
    try {
      const form = {
        ...this.formReminder,
        targetType: "event",
        targetId: eventId
      };
      const res = await new GeneralApi().setReminder(form);
      this.formReminder = {};
      this.dialog1 = false;
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطا در دریافت دسترسی ها",
        color: "danger"
      });
    }
  }

  public async addPayment(id: string): Promise<void> {
    try {
      const res: AxiosResponse = await new EventApi().addPayment(this.form, id);
      store.commit("showError", {
        message: res.data.message || "پرداخت با موفقیت ثبت شد",
        color: "success"
      });
      this.form = {};
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public async getStaff(): Promise<void> {
    try {
      const res: AxiosResponse = await new UserApi().getStaff();
      this.staff = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public getStaffItem(id: number): string {
    const x: any = this.staff.filter((x: any) => x.id == id);
    let icon = "mdi-star";
    switch (x.length > 0 ? x[0].name : "") {
      case "مشاور حضوری اول":
        icon = "mdi-alpha-i";
        break;
      case "مشاور حضوری دوم":
        icon = "mdi-roman-numeral-2";
        break;
      case "اپراتور":
        icon = "mdi-phone";
        break;
      case "مدیر سالن":
        icon = "mdi-account";
        break;
      case "DCM":
        icon = "mdi-1";
        break;
    }
    return icon;
  }

  public async deleteItem(id: string): Promise<void> {
    const confirm = window.confirm("آیا مطمئن به حذف رویداد هستید؟");
    if (!confirm) return;
    try {
      const res: AxiosResponse = await new EventApi().deleteEvent(id);
      store.commit("showError", {
        message: res.data.message || "رویداد با موفقیت حذف شد",
        color: "danger"
      });
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  staffClass(staff: string): string {
    let color;
    switch (staff) {
      case "1":
        color = "green";
        break;
      case "2":
        color = "red";
        break;
      case "3":
        color = "blue";
        break;
      case "4":
        color = "purple";
        break;
      default:
        color = "gray";
    }
    return color;
  }

  public async events(): Promise<void> {
    try {
      if (this.page == 1) {
        this.items = [];
      }
      const res: AxiosResponse = await new EventApi().list({
        ...this.form,
        page: this.page
      });
      if (window.innerWidth < 600) {
        this.items = [...this.items, ...res.data.data.data];
      }
      this.data = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("page")
  public onPageChanged() {
    this.$router.push({
      query: { page: String(this.page), search: this.form.q }
    });
    this.events();
  }

  private change($event: any) {
    this.form.office_id = $event;
    this.events();
  }

  public onFormChanged() {
    this.$router.push({
      query: { page: String(this.page), search: this.form.q }
    });
    this.fetchEntriesDebounced();
  }

  private _timerId = 0;

  fetchEntriesDebounced(): void {
    // cancel pending call
    clearTimeout(this._timerId);

    // delay new call 500ms
    this._timerId = window.setTimeout(() => {
      this.page = 1;
      this.events();
    }, 700);
  }

  @Watch("form.MobileList_id")
  public onForm2Changed() {
    this.events();
  }

  @Watch("form.type")
  public onForm3Changed() {
    this.events();
  }

  @Watch("form.user_id")
  public onForm4Changed() {
    console.log(this.form);
    this.events();
  }

  @Watch("form.creator_id")
  public onForm5Changed() {
    this.events();
  }

  @Watch("form.mentor_id")
  public onForm6Changed() {
    this.events();
  }
}
