var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto my-4",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('pre',[_vm._v("#"+_vm._s(_vm.item.item.id))]),_c('div',{staticClass:"overline mb-4"},[_vm._v(" ایجاد کننده: "),_c('a',{attrs:{"href":'/events?q=&type=all&creator_id=' +
            (_vm.item.item.creator ? _vm.item.item.creator.id : ''),"title":'برای مشاهده کلیه رویدادهای ' +
            (_vm.item.item.creator ? _vm.item.item.creator.fname : '-') +
            ' کلیک کنید.'}},[_vm._v(" "+_vm._s((_vm.item.item.creator ? _vm.item.item.creator.fname : "-") + " " + (_vm.item.item.creator ? _vm.item.item.creator.lname : ""))+" ")])]),_c('v-list-item-title',{staticClass:"headline mb-1"},[_c('a',{attrs:{"href":'/events?q=&type=all&user_id=' +
            (_vm.item.item.user ? _vm.item.item.user.id : null),"title":'برای مشاهده کلیه رویدادهای ' +
            (_vm.item.item.user ? _vm.item.item.user.fname : null) +
            ' کلیک کنید.'}},[_vm._v(" "+_vm._s((_vm.item.item.user ? _vm.item.item.user.fname : null) + " " + (_vm.item.item.user ? _vm.item.item.user.lname : null))+" ")])]),_c('v-list-item-subtitle',[_vm._l((_vm.item.item.mentors),function(x){return _c('div',{key:x.id + x.pivot.staff_id + x.pivot.event_id},[_c('p',[_c('v-chip',{attrs:{"color":_vm.staffClass(x.pivot.staff_id),"small":""}},[_vm._v(_vm._s(_vm.getStaffItem(x.pivot.staff_id)))]),_c('a',{attrs:{"href":'/events?q=&type=all&mentor_id=' + x.id,"title":'برای مشاهده کلیه رویدادهایی که ' +
                x.fname +
                ' ' +
                x.lname +
                ' به عنوان مشاور در آن‌ها حضور داشته کلیک کنید'}},[_vm._v(_vm._s("\t\t\t")+_vm._s(x.fname + " " + x.lname))])],1)])}),_c('div',[_c('p',[_vm._v(" زمان ملاقات: "),_c('strong',[_vm._v(_vm._s(_vm.item.item.meeting_jalali))])]),_c('p',[_vm._v(" ساعت: "),_c('strong',[_vm._v(_vm._s(_vm.item.item.time))])])])],2),_c('div',[_c('v-chip',{attrs:{"color":"blue","x-small":""}},[_vm._v(_vm._s(_vm.item.item.type))]),_c('v-chip',{attrs:{"color":"green","x-small":""}},[_vm._v(" "+_vm._s(_vm.item.item.office ? _vm.item.item.office.name : "نامشخص")+" ")])],1),_c('div',[_c('small',[_vm._v(" پرداخت: "),_c('strong',[_c('p',[(_vm.item.item.manual_payment === 0)?_c('span',{staticClass:"green--text"},[_vm._v("پرداخت آنلاین")]):(_vm.item.item.manual_payment === 1)?_c('span',{staticClass:"blue-grey--text"},[_vm._v("کارت به کارت")]):(_vm.item.item.manual_payment === 2)?_c('span',{staticClass:"red--text"},[_vm._v("پوز / نقدی")]):_vm._e()]),_vm._v(" | "),_c('p',[(_vm.item.item.is_paid === 1)?_c('span',{staticClass:"green--text"},[_vm._v("انجام شده")]):_c('span',{staticClass:"red--text"},[_vm._v("انجام نشده")])])])]),_c('p',{directives:[{name:"can",rawName:"v-can",value:('access control'),expression:"'access control'"}]},[_vm._v(" تاریخ ثبت: "),_c('strong',[_vm._v(_vm._s(_vm.item.item.create_jalali))])])]),_c('div',[_c('small',[_vm._v(" وضعیت: "),_c('strong',[_c('span',{class:{
                'green--text': _vm.item.item.is_done == 1,
                'red--text': _vm.item.item.is_done == 2
              }},[_vm._v(" "+_vm._s(_vm.eventStatus.filter(function (x) { return x.value == _vm.item.item.is_done; })[0] .text || "")+" ")])])])])],1),(_vm.item.item.creator)?_c('v-list-item-avatar',{attrs:{"color":"grey","size":"80","tile":""}},[(_vm.item.item.creator.avatar)?_c('img',{attrs:{"src":_vm._f("full_avatar")(_vm.item.item.creator.avatar),"alt":"Avatar"}}):_c('v-icon',[_vm._v("person")])],1):_vm._e()],1),_c('v-divider',{staticClass:"my-4"}),_c('v-card-actions',[_c('v-btn',{directives:[{name:"permission",rawName:"v-permission:any",value:('list event|own list event'),expression:"'list event|own list event'",arg:"any"}],staticClass:"mx-2",attrs:{"color":"primary","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.$router.push({
          name: 'events-view',
          params: { id: _vm.item.item.id }
        })}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('delete event'),expression:"'delete event'"}],staticClass:"mx-2",attrs:{"color":"primary","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.deleteItem(_vm.item.item.id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary","dark":"","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-content-copy")])],1)]}}]),model:{value:(_vm.copyDialog),callback:function ($$v) {_vm.copyDialog=$$v},expression:"copyDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"lighten-2"},[_vm._v("کپی لینک پرداخت")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"px-12",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","id":'pay-link-copy-' + _vm.item.item.id,"append-icon":"mdi-content-copy","filled":"","value":_vm.bms +
                  '/pay/' +
                  _vm.item.item.id +
                  '/' +
                  _vm.item.item.user.mobile.substr(-11),"label":"لینک پرداخت"},on:{"click:append":function($event){return _vm.copyLinkToClipboard(_vm.item.item.id)}}})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary","dark":"","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-comment-multiple-outline")])],1)]}}]),model:{value:(_vm.dialog_comments),callback:function ($$v) {_vm.dialog_comments=$$v},expression:"dialog_comments"}},[(_vm.dialog_comments)?_c('Comments',{attrs:{"item_id":_vm.item.item.id,"type":"event"},on:{"close":function($event){_vm.dialog_comments = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary","dark":"","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-calendar")])],1)]}}]),model:{value:(_vm.eventDialog),callback:function ($$v) {_vm.eventDialog=$$v},expression:"eventDialog"}},[_c('eventsDialog',{attrs:{"dialogItem":_vm.item},on:{"refresh-list":_vm.refreshList,"close":function($event){_vm.eventDialog = false}}})],1),(
        _vm.roles.includes('admin') ||
        _vm.roles.includes('super-admin') ||
        _vm.roles.includes('adviser')
      )?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary","dark":"","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-credit-card-outline")])],1)]}}],null,false,1681279180),model:{value:(_vm.payDialog),callback:function ($$v) {_vm.payDialog=$$v},expression:"payDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"lighten-2"},[_vm._v("پرداخت دستی این رویداد")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"px-12",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.paymentTypes,"dense":"","filled":"","error-messages":_vm.errors.payment_type,"label":"نوع پرداخت"},model:{value:(_vm.form.payment_type),callback:function ($$v) {_vm.$set(_vm.form, "payment_type", $$v)},expression:"form.payment_type"}})],1),_c('v-col',{staticClass:"px-12",attrs:{"cols":"12","sm":"6"}},[_c('file-uploader',{attrs:{"dense":"","filled":"","label":"عکس رسید پرداخت","error-messages":_vm.errors.file},on:{"input":function($event){_vm.form.file = $event}}})],1),_c('v-col',{staticClass:"px-12",attrs:{"cols":"12","sm":"6"}},[_c('v-textarea',{attrs:{"rows":"2","dense":"","filled":"","label":"توضیحات","error-messages":_vm.errors.summery},model:{value:(_vm.form.summery),callback:function ($$v) {_vm.$set(_vm.form, "summery", $$v)},expression:"form.summery"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.addPayment}},[_vm._v("ثبت پرداخت")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }