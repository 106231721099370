








import { Component, Prop, Vue } from "vue-property-decorator";
import MobileEventUserItem from "@/views/pages/events/view-components/mobile-user.vue";

@Component({
  components: {
    MobileEventUserItem: MobileEventUserItem
  }
})
export default class MobileList extends Vue {
  @Prop({ default: {} })
  item: Record<string, string> | undefined;
  private role = localStorage.getItem("roles") || [];
}
