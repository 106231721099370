









































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import { EventApi } from "@/networks/EventApi";
import store from "@/store";
import Enum from "@/config/enum";
import eventsDialog from "@/views/components/pages/dashboard/eventsDialog.vue";
import FileUploader from "@/views/components/inputs/fileUploader.vue";
import Comments from "@/views/components/pages/Comments/Comments.vue";
@Component({
  components: { FileUploader, eventsDialog, Comments }
})
export default class MobileEventUserItem extends Vue {
  @Prop({ default: {} })
  item: Record<string, any> | undefined;
  private roles = localStorage.getItem("roles") || [];
  private bms = process.env.VUE_APP_BMS;
  private payDialog = false;
  private copyDialog = false;
  private dialog_comments = false;
  private paymentTypes = Enum.TypePayment;
  private eventStatus = Enum.eventStatusAdmin;
  private form: Record<string, any> = {
    status: "1"
  };
  private errors: Record<string, Array<string>> = {};
  eventDialog = false;
  staffClass(staff: string): string {
    let color;
    switch (staff) {
      case "1":
        color = "green";
        break;
      case "2":
        color = "red";
        break;
      case "3":
        color = "blue";
        break;
      case "4":
        color = "purple";
        break;
      default:
        color = "gray";
    }
    return color;
  }
  refreshList(): number {
    console.log("refresh-list");
    return 0;
  }
  public getStaffItem(id: number): Promise<void> {
    const x: any = (this.item as any).staff.filter((x: any) => x.id == id);
    return x.length > 0 ? x[0].name : "";
  }

  copyLinkToClipboard(id: string): void {
    let copyText = (document as any).getElementById("pay-link-copy-" + id);
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    navigator.clipboard
      .writeText(copyText.value)
      .then(() => {
        store.commit("showError", {
          message: "لینک با موفقیت کپی شد ",
          color: "success"
        });
      })
      .catch((error) => {
        alert(`Copy failed! ${error}`);
      });
  }

  public async deleteItem(id: string): Promise<void> {
    const confirm = window.confirm("آیا مطمئن به حذف رویداد هستید؟");
    if (!confirm) return;
    try {
      const res: AxiosResponse = await new EventApi().deleteEvent(id);
      store.commit("showError", {
        message: res.data.message || "رویداد با موفقیت حذف شد",
        color: "danger"
      });
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public async addPayment(): Promise<void> {
    try {
      const res: AxiosResponse = await new EventApi().addPayment(
        this.form,
        this.item?.item?.id
      );
      this.form = {
        status: "1"
      };
      store.commit("showError", {
        message: res.data.message || "پرداخت با موفقیت ثبت شد",
        color: "success"
      });
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }
}
